<template>
  <div class="Article" :class="{ Small: small }" :selected="selected">
    <div v-if="isFetching" class="LoadingWrapper">
      <vcl-facebook />
    </div>
    <div
      v-if="!isFetching && Boolean(article.category)"
      class="ContentContainer"
      @click="goTo(article)"
    >
      <div class="CoverImage">
        <div class="CoverImageInner">
          <img
            class="Placeholder"
            src="/assets/images/CnewsMonkey.svg"
            alt="Comiso News Logo Scimmia"
          />
          <v-lazy-image :alt="article.title" :src="article.imageUrl" />
        </div>
      </div>
      <div class="Content">
        <h1 class="Title">
          {{ article.title }}
        </h1>
        <div class="Details">
          <div class="Author">
            {{ author }}
          </div>
          <div class="Note">
            <span class="Date">
              <img
                class="IconClock"
                src="/assets/icons/clock.svg"
                alt="Icona orologio"
              />
              <span class="Text">
                {{ formatToNow(article.publishedAt) }}
              </span>
            </span>
            <span class="Category">
              <nuxt-link class="WithLine" :to="article.category.slug.current">
                {{ article.category.title }}
              </nuxt-link>
            </span>
          </div>
          <div v-if="!small" class="Abstract">
            {{ article.abstract }}
          </div>
          <div class="Actions">
            <nuxt-link :to="buildInternalLink(article)">
              <span class="Text"> Leggi </span>
              <span class="ArrowRight" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VclFacebook } from 'vue-content-loading';

import { formatToNow } from '~/lib/date';
import { buildInternalLink } from '~/lib/resources';

export default {
  components: {
    VclFacebook,
  },
  props: {
    isFetching: {
      type: Boolean,
      value: true,
    },
    article: {
      type: Object,
      default() {
        return {};
      },
    },
    selected: {
      type: Boolean,
      value: false,
    },
    small: {
      type: Boolean,
      value: false,
    },
  },
  computed: {
    author(props) {
      return props.article.author;
    },
  },
  methods: {
    formatToNow,
    buildInternalLink,
    goTo(article) {
      this.$router.push({ path: buildInternalLink(article) });
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/media.pcss';
@import '~/assets/mixins.pcss';

.LoadingWrapper {
  padding: 20px;
}

.Article {
  user-select: none;
  border-radius: 10px;
  background-color: $main-color;
  color: $white;
  box-shadow: $shadow-1;
  position: relative;

  & a {
    color: $white;
  }

  & h1 {
    color: $white;
    pointer-events: none;
    font-size: 1.2em;
    padding: 0 $main-padding;
    margin: 20px 0;
  }

  & h1::after {
    margin-top: 5px;
    border-color: $white;
  }
}

.ContentContainer {
  cursor: pointer;
}

.IconClock {
  height: 25px;
  margin-right: 5px;
}

.Details {
  padding: 0 $main-padding $main-padding;
}

.Note {
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Category {
  font-size: 18px;
  color: $white;
  padding: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.Date {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.Abstract {
  max-height: 500px;
  overflow-y: hidden;
  transition: all 0.5s ease-in-out;
  text-align: justify;
}

.CoverImage {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  background-color: $white;
  border-radius: 10px 10px 0 0;
  box-shadow: $shadow-1;

  & .CoverImageInner {
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    height: 280px;
  }

  & .v-lazy-image {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .v-lazy-image-loaded {
    opacity: 1;
  }

  & .Placeholder {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.Actions {
  display: block;
  margin-top: 10px;
  text-align: right;

  & a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
  }

  & a::after {
    content: none;
  }

  & .Text {
    color: $white;
    font-weight: bold;

    @mixin mediumFont;
  }

  & .ArrowRight {
    display: block;
    text-indent: -9999px;
    width: 35px;
    height: 33px;
    background: url(/assets/icons/arrow-right.svg) no-repeat;
    background-size: cover;
  }
}

.Article.Small {
  & h1 {
    font-size: 1em;
  }

  .Category {
    font-size: 1em;
  }

  .Date {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
  }
}

@media (--medium) {
  .Article:not(.Small) {
    & .ContentContainer {
      display: grid;
      grid-template-columns: 1fr 2fr;

      & .CoverImage {
        display: flex;
        width: auto;
        height: 100%;
        max-height: none;
        align-items: center;
        box-shadow: none;
        border-radius: 10px 0 0 10px;
      }

      & .CoverImageInner {
        width: 100%;
      }
    }
  }
}
</style>
