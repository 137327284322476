import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: 'eh8rswn4',
  dataset: process.env.DATASET,
  useCdn: process.env.ENVIRONMENT === 'production',
  apiVersion: '2021-03-25',
});

const makeQuery = (query) => query.replace(/\t/gi, '').replace(/\n/gi, '');

export const fetchMainNews = () => {
  const query = makeQuery(`
  *[_type == $type]{
    slug,
    title,
    abstract,
    publishedAt,
    imageUrl,
    "author": author->name,
    "category": category->{slug, title},
  } | order(publishedAt desc)[0...3]`);
  const params = {
    type: 'post',
  };

  return client.fetch(query, params);
};

export const fetchCategoryNewsCount = (categorySlug) => {
  const query = makeQuery(`
  count(*[_type == $type && category._ref in *[_type == "category" && slug.current == $categorySlug]._id])`);
  const params = {
    type: 'post',
    categorySlug,
  };

  return client.fetch(query, params);
};

export const fetchCategoryNews = (categorySlug, init, end) => {
  const query = makeQuery(`
  *[_type == $type && category._ref in *[_type == "category" && slug.current == $categorySlug]._id]{
    slug,
    title,
    abstract,
    publishedAt,
    imageUrl,
    "author": author->name,
    "category": category->{slug, title},
  } | order(publishedAt desc)[$init...$end]`);

  const params = {
    type: 'post',
    categorySlug,
    init,
    end,
  };

  return client.fetch(query, params);
};

export const fetchRelatedNews = (categorySlug, avoidNewsId) => {
  const query = makeQuery(`
  *[_type == $type && _id != $newsId && category._ref in *[_type == "category" && slug.current == $categorySlug]._id]{
    slug,
    title,
    abstract,
    publishedAt,
    imageUrl,
    "author": author->name,
    "category": category->{slug, title},
  } | order(publishedAt desc)[$init...$end]`);

  const params = {
    type: 'post',
    categorySlug,
    newsId: avoidNewsId,
    init: 0,
    end: 3,
  };

  return client.fetch(query, params);
};

export const fetchNews = (articleSlug) => {
  const query = makeQuery(`
  *[_type == $type && slug.current == $articleSlug]{
    ...,
    "author": author->name,
    "category": category->{slug, title},
  } | order(publishedAt desc)[0]`);

  const params = {
    type: 'post',
    articleSlug,
  };

  return client.fetch(query, params);
};

export const fetchNewsByQuery = (searchQuery, init, end) => {
  const query = makeQuery(`
  *[_type == $type && [title, body] match [$searchQuery]]{
    slug,
    title,
    abstract,
    publishedAt,
    imageUrl,
    "author": author->name,
    "category": category->{slug, title},
  } | order(publishedAt desc)[$init...$end]`);

  const params = {
    type: 'post',
    searchQuery: `${searchQuery}*`,
    init,
    end,
  };

  return client.fetch(query, params);
};

export default client;
